.gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
.gallery-header {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  
.gallery-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
  
.gallery-image img {
    margin: 1rem;
    width: 1920px;
    height: 1200px;
    object-fit: cover;
  }
  