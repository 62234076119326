.header {
    width: 100%;
    display: flex;
    justify-content: right; 
    align-items: center;
    padding: 1rem .5rem;
    background-color: #1D1D1D;
  
    .logo {
      font-size: 2rem;
      font-weight: bold;
      letter-spacing: 2px;
    }

    .links {
      display: flex;
      justify-content: space-between;
      list-style: none;
      align-items: center;
      width: 30%;
  
      a {
        color: white;
        text-decoration: none;
        padding: 0.5rem 1rem;
        font-size: 2rem;
        transition: all 0.2s ease-in-out;
  
        &:hover {
          color: #ff9900;
        }
      }

    }
  
    .nav-links {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30%;
  
      a {
        color: white;
        text-decoration: none;
        padding: 0.5rem 1rem;
        font-size: 3rem;
        transition: all 0.2s ease-in-out;
  
        &:hover {
          color: #ff9900;
        }
      }
    }
  
    .menu-icon {
      font-size: 2rem;
      cursor: pointer;
      display: none;
    }
}

@media screen and (max-width: 1024px) {
  .nav-links {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .menu-icon {
    display: block;
  }
}