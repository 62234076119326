.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 0px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .form__header {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .form__label {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .form__input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .form__textarea {
    width: 100%;
    height: 150px;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    resize: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  .form__button {
    position: bottom;
    width: 100%;
    padding: 10px;
    background-color: #ff9900;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form__button:hover {
    background-color: #6f00af;
  }
  