.home-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-top: 10px;
    color: white;
  
    h1 {
      justify-content: center;
      font-size: 5rem;
      margin-bottom: 2px;
      text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
    }
  
    p {
      font-size: 3rem;
      margin-bottom: 1rem;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.2);
    }
  
    button {
      padding: 1rem 2rem;
      font-size: 2rem;
      background-color: #ff9900;
      color: black;
      border: none;
      border-radius: 5px;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      transition: all 0.2s ease-in-out;
  
      &:hover {
        cursor: pointer;
        transform: translateY(-5px);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
      }
    }
  }
  