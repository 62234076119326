.about-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    width: 100%;
    padding-top: 50px;
    color: white;
  }
  
  .about-text {
    text-align: center;
    max-width: 800px;
    margin: 0 2rem;
  }
  
  .about-title {
    font-size: 3rem;
    margin-bottom: .1rem;
  }
  
  .about-paragraph {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  .about-image {
    max-width: 750px;
    margin-left: 2rem;
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  