.samples-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top: 50px;
}

.samples-header {
margin-bottom: 50px;
font-size: 3rem;
text-align: center;
}

.soundcloud-container {
width: 80%;
max-width: 800px;
margin-bottom: 50px;
}
  